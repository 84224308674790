import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "messages/";
import authHeader from "../services/auth-header";
export const messages = {
  state: {
    messages: [],
  },
  getters: {
    allMessages: (state) => state.messages,
  },
  mutations: {
    setMessages(state, messages) {
      state.messages = messages;
    },
  },
  actions: {
    async getMessages(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          //console.log(res);
          context.commit("setMessages", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    async createMessages(context, data) {
      //console.log(data);
      return axios
        .post(
          apiURL,
          {
            message: data.message,
            deleteMsg: data.deleteMsg,
            lineGroup: data.lineGroup,
            timer: parseInt(data.timer),
            number: parseInt(data.number),
            building: data.building,
            notification: data.notification,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    deleteMessage(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then((res) => {
          //console.log("Delete is successfully");
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    async editMessage(constext, data) {
      //console.log(data);
      return axios
        .put(
          apiURL + data.id,
          {
            build: data.building,
            message: data.message,
            deleteMsg: data.deleteMsg,
            lineGroup: data.lineGroup,
            timer: parseInt(data.timer),
            number: parseInt(data.number),
            notification: data.notification,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          //console.log(error)
          return error;
        });
    },
  },
};

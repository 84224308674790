import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "reports/";
import authHeader from "../services/auth-header";
export const reports = {
  state: {
    reports: [],
    report: [],
    reportBytype: [],
    typeReports: [],
  },
  getters: {
    reportsBuliding: (state) => state.reports,
    reportBuliding: (state) => state.report,
    reportBytype: (state) => state.reportBytype,
    typeReports: (state) => state.typeReports,
  },
  actions: {
    getReports({ commit }) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data.type);
          commit("getReports", res.data.data);
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    getReportByID({ commit }, data) {
      if (data.type == "All") {
        data.type = "";
      }
      return axios
        .post(
          apiURL,
          {
            building: data.id,
            typeSearch: data.type,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res.data.data);
          commit("getReport", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    getReportByIDType({ commit }, data) {
      if (data.type == "All") {
        data.type = "";
      }
      return axios
        .post(
          apiURL,
          {
            building: data.id,
            typeSearch: data.type,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res.data.data);
          commit("getReportByType", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    getReportBySearch({ commit }, data) {
      console.log(data);
      return axios
        .post(
          apiURL + "search",
          {
            building: data.id,
            typeSearch: data.type,
            eventStart: data.eventStart,
            eventEnd: data.eventEnd,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("getReportBySearch", res.data.data.report);
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
  },
  mutations: {
    getReport(state, report) {
      state.report = report;
    },
    getReportByType(state, reportBytype) {
      state.reportBytype = reportBytype;
    },
    getReportBySearch(state, report) {
      state.reports = report;
    },
    getReports(state, report) {
      state.reports = report;
    },
  },
};

<template>
  <div v-resize="onResize">
    <v-container fluid>
      <v-breadcrumbs :items="itemsRouter">
        <template v-slot:divider>
          <v-icon size="25">mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
            <span :style="`color: ${item.color}`">
              {{ item.text.toUpperCase() }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card id="card" class="mx-6 mb-10">
        <v-card-title>
          Messages Setting
          <v-spacer></v-spacer>
          <v-text-field
            ref="search"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            class="shrink"
            style="width: 300px"
            color="#D32F2F"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items-per-page="5"
          :items="getMessages"
        >
          <template v-slot:[`item.lineGroup`]="{ item }">
            <div v-for="line in item.lineGroup">{{ line.groupName }}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }" v-if="!isMobile">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  @click="editData(item)"
                  color="success"
                  icon
                >
                  <v-icon>mdi-pencil</v-icon></v-btn
                >
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  @click="delData(item)"
                  color="error"
                  icon
                >
                  <v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }" v-else>
            <v-btn @click="editData(item)" color="success" icon>
              <v-icon>mdi-pencil</v-icon></v-btn
            >
            <v-btn @click="delData(item)" color="error" icon>
              <v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
          <template v-slot:[`item.notification`]="{ item }">
            <v-simple-checkbox
              :ripple="false"
              v-model="item.notification"
              @click="checkboxUpdated(item)"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
        <v-btn
          style="position: fixed; right: 0px"
          :style="isMobile || isApp ? 'bottom: 60px;' : 'bottom: 5px;'"
          class="mx-8"
          fixed
          fab
          color="#D32F2F"
          dark
          absolute
          bottom
          right
          dense
          floating
          @click="form = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-dialog v-model="form" persistent max-width="600px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h5"
                  >Config Message Detail</span
                ></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="addMessages()"
                  @keyup.native.enter="addMessages()"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-row align="center" justify="start">
                      <v-col
                        cols="4"
                        lg="4"
                        md="5"
                        sm="5"
                        justify-self="center"
                      >
                        <h3>Stage On* :</h3>
                      </v-col>
                      <v-col
                        cols="8"
                        lg="8"
                        md="5"
                        sm="5"
                        class="mt-n4"
                        justify-self="center"
                      >
                        <v-text-field
                          name="Message Stage On"
                          label="Message Stage On*"
                          hide-details
                          :rules="[
                            (v) => !!v || 'Message Stage On is required',
                          ]"
                          required
                          v-model="dataAdd.message"
                          color="#000"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="start">
                      <v-col cols="4" lg="4" md="5" sm="5">
                        <h3>Stage Off :</h3>
                      </v-col>
                      <v-col
                        cols="8"
                        lg="8"
                        md="5"
                        sm="5"
                        class="mt-n4"
                        justify-self="center"
                      >
                        <v-text-field
                          name="Message Stage Off"
                          label="Message Stage Off"
                          hide-details
                          v-model="dataAdd.deleteMsg"
                          color="#000"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="start">
                      <v-col cols="4" lg="4" md="5" sm="5">
                        <h3>Number per alarm* :</h3>
                      </v-col>
                      <v-col
                        cols="8"
                        lg="8"
                        md="5"
                        sm="5"
                        class="mt-n4"
                        justify-self="center"
                      >
                        <v-text-field
                          type="number"
                          name="Number"
                          label="Number per alarm*"
                          hide-details
                          min="0"
                          :rules="[
                            numberRule,
                            (v) => !!v || 'Number per alarm is required',
                          ]"
                          required
                          v-model="dataAdd.number"
                          color="#000"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="start">
                      <v-col cols="4" lg="4" md="5" sm="5">
                        <h3>Timer per alarm :</h3>
                      </v-col>
                      <v-col
                        cols="8"
                        lg="8"
                        md="5"
                        sm="5"
                        class="mt-n4"
                        justify-self="center"
                      >
                        <v-text-field
                          type="number"
                          name="Timer"
                          min="0"
                          label="Timer per alarm (minute)*"
                          hide-details
                          :rules="[
                            numberRule,
                            (v) => !!v || 'Timer per alarm is required',
                          ]"
                          required
                          v-model="dataAdd.timer"
                          color="#000"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="start">
                      <v-col cols="4" lg="4" md="5" sm="5">
                        <h3>Notification :</h3>
                      </v-col>
                      <v-col
                        cols="8"
                        lg="8"
                        md="5"
                        sm="5"
                        class="mt-n4"
                        justify-self="center"
                      >
                        <v-checkbox
                          hide-details
                          color="red"
                          v-model="dataAdd.notification"
                          :label="
                            dataAdd.notification
                              ? 'Notification On'
                              : 'Notification Off'
                          "
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="start">
                      <v-col cols="4" lg="4" md="5" sm="5">
                        <h3>Group Line :</h3>
                      </v-col>
                      <v-col
                        cols="8"
                        lg="8"
                        md="5"
                        sm="5"
                        class="mt-n4"
                        justify-self="center"
                      >
                        <v-select
                          :items="getLines"
                          label="Group Line*"
                          item-value="id"
                          item-text="groupName"
                          :rules="[(v) => !!v || 'Group Line is required']"
                          required
                          multiple
                          hide-details
                          v-model="selectedLines"
                          color="#000"
                          item-color="#D32F2F"
                        >
                          <template v-slot:prepend-item>
                            <v-list-item
                              ripple
                              @mousedown.prevent
                              @click="toggle"
                            >
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    selectedLines.length > 0 ? '#D32F2F' : ''
                                  "
                                >
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn id="btnsave" text @click="addMessages()"> Submit </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog v-model="formEdit" persistent max-width="600px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h5"
                  >Edit Config Message Detail</span
                ></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="editMessage()"
                  @keyup.native.enter="editMessage()"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-row align="center" justify="start">
                      <v-col
                        cols="4"
                        lg="4"
                        md="5"
                        sm="5"
                        justify-self="center"
                      >
                        <h3>Stage On* :</h3>
                      </v-col>
                      <v-col
                        cols="8"
                        lg="8"
                        md="5"
                        sm="5"
                        class="mt-n4"
                        justify-self="center"
                      >
                        <v-text-field
                          name="Message Stage On"
                          label="Message Stage On*"
                          hide-details
                          :rules="[
                            (v) => !!v || 'Message Stage On is required',
                          ]"
                          required
                          v-model="dataEdit.message"
                          color="#000"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="start">
                      <v-col cols="4" lg="4" md="5" sm="5">
                        <h3>Stage Off :</h3>
                      </v-col>
                      <v-col
                        cols="8"
                        lg="8"
                        md="5"
                        sm="5"
                        class="mt-n4"
                        justify-self="center"
                      >
                        <v-text-field
                          name="Message Stage Off"
                          label="Message Stage Off"
                          hide-details
                          v-model="dataEdit.deleteMsg"
                          color="#000"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="start">
                      <v-col cols="4" lg="4" md="5" sm="5">
                        <h3>Number per alarm* :</h3>
                      </v-col>
                      <v-col
                        cols="8"
                        lg="8"
                        md="5"
                        sm="5"
                        class="mt-n4"
                        justify-self="center"
                      >
                        <v-text-field
                          type="number"
                          name="Number"
                          label="Number per alarm*"
                          hide-details
                          min="0"
                          :rules="[
                            numberRule,
                            (v) => !!v || 'Number per alarm is required',
                          ]"
                          required
                          v-model="dataEdit.number"
                          color="#000"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="start">
                      <v-col cols="4" lg="4" md="5" sm="5">
                        <h3>Timer per alarm :</h3>
                      </v-col>
                      <v-col
                        cols="8"
                        lg="8"
                        md="5"
                        sm="5"
                        class="mt-n4"
                        justify-self="center"
                      >
                        <v-text-field
                          type="number"
                          name="Timer"
                          min="0"
                          label="Timer per alarm (minute)*"
                          hide-details
                          :rules="[
                            numberRule,
                            (v) => !!v || 'Timer per alarm is required',
                          ]"
                          required
                          v-model="dataEdit.timer"
                          color="#000"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="start">
                      <v-col cols="4" lg="4" md="5" sm="5">
                        <h3>Notification :</h3>
                      </v-col>
                      <v-col
                        cols="8"
                        lg="8"
                        md="5"
                        sm="5"
                        class="mt-n4"
                        justify-self="center"
                      >
                        <v-checkbox
                          hide-details
                          color="red"
                          v-model="dataEdit.notification"
                          :label="
                            dataEdit.notification
                              ? 'Notification On'
                              : 'Notification Off'
                          "
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="start">
                      <v-col cols="4" lg="4" md="5" sm="5">
                        <h3>Group Line :</h3>
                      </v-col>
                      <v-col
                        cols="8"
                        lg="8"
                        md="5"
                        sm="5"
                        class="mt-n4"
                        justify-self="center"
                      >
                        <v-select
                          :items="getLines"
                          label="Group Line*"
                          item-value="id"
                          item-text="groupName"
                          :rules="[(v) => !!v || 'Group Line is required']"
                          required
                          multiple
                          hide-details
                          v-model="selectedLines"
                          color="#000"
                          item-color="#D32F2F"
                        >
                          <template v-slot:prepend-item>
                            <v-list-item
                              ripple
                              @mousedown.prevent
                              @click="toggle"
                            >
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    selectedLines.length > 0 ? '#D32F2F' : ''
                                  "
                                >
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn id="btnsave" text @click="editMessage()"> Submit </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog v-model="confirmDelete" max-width="300">
          <v-card>
            <v-card-title primary-title> Confirm Delete </v-card-title>

            <v-card-text class="body">
              Are you sure to delete this config message ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>

              <v-btn color="error" text @click="delMessage()"> Confirm </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
      <v-snackbar v-model="snackbar" :color="color" buttom left timeout="1500">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "messages",
  data: () => ({
    isMobile: false,
    isApp: false,
    numberRule: (val) => {
      if (val < 0) return "Please enter a positive number";
      return true;
    },
    itemsRouter: [
      {
        text: "Dashboard",
        disabled: false,
        color: "red",
        href: "/dashboard",
      },
      {
        text: "Plant",
        disabled: true,
      },
    ],
    dataAdd: {
      message: "",
      lineGroup: [],
      timer: "",
      number: "",
      building: "",
      deleteMsg: "",
      notification: false,
    },
    dataEdit: {
      id: null,
      message: null,
      lineGroup: [],
      timer: null,
      number: null,
      building: null,
      deleteMsg: "",
      notification: null,
    },
    messageId: null,
    selectedLines: [],
    confirmDelete: false,
    newConfig: null,
    formEdit: false,
    snackbar: false,
    idBuliding: null,
    text: "",
    color: "",
    form: false,
    search: "",
    headers: [
      { text: "Message Stage On", value: "message", sortable: true },
      { text: "Message Stage Off", value: "deleteMsg", sortable: true },
      { text: "Group Line", value: "lineGroup", sortable: true },
      { text: "Number per alarm ", value: "number" },
      { text: "Timer per alarm(minute)", value: "timer" },
      { text: "Action", value: "actions", sortable: false, align: "center" },
      {
        text: "Notification",
        value: "notification",
        sortable: false,
        align: "center",
      },
    ],
  }),
  computed: {
    getLines() {
      return this.$store.getters.allLines;
    },
    getMessages() {
      return this.$store.getters.allMessages;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    allLines() {
      return this.selectedLines.length === this.getLines.length;
    },
    someLines() {
      return this.selectedLines.length > 0 && !this.allLines;
    },
    icon() {
      if (this.allLines) return "mdi-close-box";
      if (this.someLines) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  mounted() {
    this.idBuliding = Cookies.get("idBuliding");
    if (!this.currentUser || this.idBuliding == null) {
      this.$router.push("/");
    } else {
      this.loadPage();
    }
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode) {
      ////console.log("webapp is installed");
      this.isApp = true;
    }
  },
  methods: {
    onResize() {
      if (window.innerWidth < 960) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    loadPage() {
      this.idBuliding = Cookies.get("idBuliding");
      this.$store.dispatch("getLines");
      this.$store.dispatch("getMessages", this.idBuliding);
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allLines) {
          this.selectedLines = [];
        } else {
          this.selectedLines = this.getLines.slice();
        }
      });
    },
    closeDlg() {
      this.form = false;
      this.formEdit = false;
      this.confirmDelete = false;
      this.dataAdd.message = "";
      this.selectedLines = [];
      this.dataAdd.timer = "";
      this.dataAdd.number = "";
      this.dataAdd.lineGroup = [];
      this.dataAdd.timer = "";
      this.dataAdd.number = "";
      this.dataAdd.deleteMsg = "";
      this.dataEdit.lineGroup = [];
    },
    async addMessages() {
      if (
        this.selectedLines == "" ||
        this.dataAdd.timer == "" ||
        this.dataAdd.number == "" ||
        this.dataAdd.message == "" ||
        this.selectedLines == []
      ) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! please fill out this form";
      } else if (this.dataAdd.number < 0) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Please enter a positive number";
        this.dataAdd.number = "";
      } else if (this.dataAdd.timer < 0) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Please enter a positive number";
        this.dataAdd.timer = "";
      } else {
        if (this.selectedLines[0]._id == undefined) {
          for (let i = 0; i < this.selectedLines.length; i++) {
            const index = this.getLines.findIndex(
              (x) => x.id === this.selectedLines[i]
            );
            let obj = {
              id: this.selectedLines[i],
              groupName: this.getLines[index].groupName,
            };
            this.dataAdd.lineGroup.push(obj);
          }
        } else {
          for (let i = 0; i < this.selectedLines.length; i++) {
            let obj = {
              id: this.selectedLines[i].id,
              groupName: this.selectedLines[i].groupName,
            };
            this.dataAdd.lineGroup.push(obj);
          }
        }
        this.dataAdd.building = Cookies.get("idBuliding");
        //console.log(this.dataAdd);
        await this.$store
          .dispatch("createMessages", this.dataAdd)
          .then((res) => {
            this.color = "success";
            this.text = "Config message has been successfully saved.";
            this.snackbar = true;
            this.closeDlg();
            this.loadPage();
          })
          .catch((error) => {
            //console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.text = "Error! You cannot create a config message.";
            this.selectedLines = [];
          });
      }
    },
    delData(data) {
      ////console.log(id._id)
      this.confirmDelete = true;
      this.messageId = data._id;
    },
    delMessage() {
      this.$store
        .dispatch("deleteMessage", this.messageId)
        .then(() => {
          this.closeDlg();
          this.loadPage();
          this.color = "success";
          this.text = "Deleted! Config message has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          //console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this config message.";
          this.snackbar = true;
        });
    },
    editData(data) {
      //console.log(data);
      this.formEdit = true;
      this.dataEdit.id = data._id;
      this.dataEdit.message = data.message;
      this.dataEdit.deleteMsg = data.deleteMsg;
      this.selectedLines = data.lineGroup;
      this.dataEdit.timer = data.timer;
      this.dataEdit.number = data.number;
      this.dataEdit.building = data.build;
      this.dataEdit.notification = data.notification;
      ////console.log(this.selectedLines);
    },
    async editMessage() {
      if (
        this.selectedLines === [] ||
        this.selectedLines == "" ||
        this.dataEdit.timer === "" ||
        this.dataEdit.number === "" ||
        this.dataEdit.message === ""
      ) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! please fill out this form";
      } else if (this.dataEdit.number < 0) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Please enter a positive number";
        this.dataEdit.number = "";
      } else if (this.dataEdit.timer < 0) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Please enter a positive number";
        this.dataEdit.timer = "";
      } else {
        if (this.selectedLines[0]._id == undefined) {
          for (let i = 0; i < this.selectedLines.length; i++) {
            const index = this.getLines.findIndex(
              (x) => x.id === this.selectedLines[i]
            );
            let obj = {
              id: this.selectedLines[i],
              groupName: this.getLines[index].groupName,
            };
            this.dataEdit.lineGroup.push(obj);
          }
        } else {
          for (let i = 0; i < this.selectedLines.length; i++) {
            let obj = {
              id: this.selectedLines[i].id,
              groupName: this.selectedLines[i].groupName,
            };
            this.dataEdit.lineGroup.push(obj);
          }
        }
        //console.log(this.dataEdit);
        await this.$store
          .dispatch("editMessage", this.dataEdit)
          .then(() => {
            this.color = "success";
            this.text = "Success! Config Message has been edited.";
            this.snackbar = true;
            this.loadPage();
            this.closeDlg();
          })
          .catch((error) => {
            //console.log(error);
            this.color = "error";
            this.text = " Error! You cannot edit this config Message.";
            this.snackbar = true;
          });
      }
    },
    checkboxUpdated(data) {
      //console.log(data);
      data.building = data.build;
      this.$store
        .dispatch("editMessage", data)
        .then(() => {
          this.loadPage();
          this.closeDlg();
          this.color = "success";
          this.text = "Success! Config Message has been edited.";
          this.snackbar = true;
        })
        .catch((error) => {
          //console.log(error);
          this.color = "error";
          this.text = " Error! You cannot edit this config Message.";
          this.snackbar = true;
        });
    },
  },
};
</script>

<style>
#btnsave:hover {
  color: green;
}
.add {
  position: fixed;
  bottom: 500px;
  right: 20px;
}
</style>

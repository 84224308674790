import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "logs/";
import authHeader from "../services/auth-header";
export const logs = {
  state: {
    logs: [],
  },
  getters: {
    allLogs: (state) => state.logs,
  },
  mutations: {
    setLogs(state, logs) {
      state.logs = logs;
    },
  },
  actions: {
    async getLogs(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data);
          context.commit("setLogs", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error.response;
        });
    },
  },
};

<template>
  <v-app
    id="login"
    class="secondary"
    style="background: linear-gradient(to top, #f5f5f5 50%, #d32f2f 50%)"
  >
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs11 sm8 md5 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img
                    :src="
                      require('/public/img/icons/apple-touch-icon-180x180.png')
                    "
                    width="180"
                    height="180"
                  />
                  <span
                    class="flex my-4 red--text text--darken-1 text-h5 font-weight-medium"
                  >
                    Fire Alarms Monitoring
                  </span>
                </div>
                <v-form @keyup.native.enter="login">
                  <v-text-field
                    append-icon="mdi-account"
                    name="username"
                    label="Username"
                    type="text"
                    v-model="user.username"
                    :rules="[rules.required]"
                    color="#000"
                    @keypress="isLetter($event)"
                  />
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="
                      hidePassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                    "
                    name="password"
                    label="Password"
                    id="password"
                    :rules="[rules.required]"
                    v-model="user.password"
                    @click:append="hidePassword = !hidePassword"
                    @keypress="isLetter($event)"
                    color="#000"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="white--text"
                  block
                  color="red darken-2"
                  @click="login"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar v-model="showResult" timeout="1500" top>
        {{ result }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      user: {
        username: null,
        password: null,
      },
      showResult: false,
      result: "",
      hidePassword: true,
      rules: {
        required: (value) => !!value || "Required.",
      },
      lastPath: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
  },
  beforeRouteEnter(to, from, next) {
    // Do something
    //next({ path: "/foo" }); // Go somewhere else if necessary
    //console.log(from);
    if (from.name == "Events")
    {
      Cookies.set("lastPath", from.path);
    }
    next(); // Or stay here
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    login() {
      if (!this.user.username || !this.user.password) {
        this.result = "Username and Password can't be null.";
        this.showResult = true;
        return;
      } else {
        this.$store.dispatch("auth/login", this.user).then(
          (res) => {
            this.lastPath = Cookies.get("lastPath");
            this.$store.dispatch("auth/decode").then(() => {
              if (this.getAccess.firstLogin) {
                this.$router.push("/resetPassword");
              } else {
                if (this.lastPath) {
                  this.$router.push(this.lastPath);
                } else {
                  this.$router.push("/dashboard");
                }
                
              }
            });
          },
          (error) => {
            //console.log(error);
            this.message = "Incorrect username or password";
            this.loading = false;
            this.error = true;
            this.result = this.message;
            this.showResult = true;
          }
        );
      }
    },
  },
};
</script>
<style></style>

<template>
  <div v-resize="onResize">
    <v-container fluid>
      <v-breadcrumbs :items="itemsRouter">
        <template v-slot:divider>
          <v-icon size="25">mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
            <span :style="`color: ${item.color}`">
              {{ item.text.toUpperCase() }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card id="card" class="mx-6 mb-10">
        <v-card-title>
          Plants
          <v-spacer></v-spacer>
          <v-text-field
            ref="search"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            class="shrink"
            style="width: 300px"
            color="#D32F2F"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          item-key="id"
          :search="search"
          :headers="headers"
          :items="getBuilding"
          :items-per-page="5"
        >
          <template v-slot:[`item.actions`]="{ item }" v-if="!isMobile">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  @click="editData(item)"
                  color="success"
                  icon
                >
                  <v-icon>mdi-pencil</v-icon></v-btn
                >
              </template>
              <span>Edit Plant</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="error"
                  @click="delData(item.id)"
                  icon
                  ><v-icon>mdi-delete</v-icon>
                </v-btn></template
              >
              <span>Delete</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }" v-else>
            <v-btn @click="editData(item)" color="success" icon>
              <v-icon>mdi-pencil</v-icon></v-btn
            >
            <v-btn color="error" @click="delData(item.id)" icon
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
        <v-btn
          style="position: fixed; right: 0px"
          :style="isMobile || isApp ? 'bottom: 60px;' : 'bottom: 5px;'"
          class="mx-8"
          fixed
          fab
          color="#D32F2F"
          dark
          absolute
          bottom
          right
          dense
          floating
          @click="form = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <div id="Form">
          <v-dialog v-model="form" persistent max-width="600px">
            <template>
              <v-card>
                <v-card-title
                  ><span class="text-h4">Create Plant</span></v-card-title
                >
                <v-card-text>
                  <v-form
                    @submit.prevent="addPlant()"
                    @keyup.native.enter="addPlant()"
                    lazy-validation
                    enctype="multipart/form-data"
                    autocomplete="off"
                  >
                    <v-container fluid>
                      <v-text-field
                        name="name"
                        label="Name*"
                        v-model="dataAdd.name"
                        :rules="[(v) => !!v || 'Name is required']"
                        required
                        color="#000"
                        type="text"
                      ></v-text-field>

                      <v-text-field
                        type="text"
                        name="macAddress"
                        label="MAC Address*"
                        v-model="dataAdd.macAddress"
                        :rules="[(v) => !!v || 'MAC Address is required']"
                        required
                        color="#000"
                      ></v-text-field>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeDlg()"> Cancel </v-btn>
                  <v-btn id="btnsave" color="success" text @click="addPlant()">
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
        <div id="editForm">
          <v-dialog v-model="editform" persistent max-width="600px">
            <template>
              <v-card>
                <v-card-title
                  ><span class="text-h5">Edit Profile</span></v-card-title
                >
                <v-card-text>
                  <v-form
                    @submit.prevent="editPlant()"
                    @keyup.native.enter="editPlant()"
                    ref="dataEdit"
                    lazy-validation
                    enctype="multipart/form-data"
                    autocomplete="off"
                  >
                    <v-container fluid>
                      <v-text-field
                        name="name"
                        label="Name*"
                        v-model="dataEdit.name"
                        :rules="[(v) => !!v || 'Name is required']"
                        required
                        color="#000"
                      ></v-text-field>
                      <v-text-field
                        name="macAddress"
                        label="MAC Address*"
                        v-model="dataEdit.macAddress"
                        :rules="[(v) => !!v || 'MAC Address is required']"
                        required
                        color="#000"
                      ></v-text-field>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeDlg()"> Cancel </v-btn>
                  <v-btn id="btnsave" color="success" text @click="editPlant()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
        <v-dialog v-model="confirmDelete" max-width="300">
          <v-card>
            <v-card-title primary-title> Confirm Delete </v-card-title>

            <v-card-text class="body">
              Are you sure to delete this plant ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>

              <v-btn color="error" text @click="delPlant()"> Confirm </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
      <v-snackbar v-model="snackbar" buttom left :color="color" timeout="1500">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "plants",
  data: () => ({
    isMobile: false,
    isApp: false,
    snackbar: false,
    text: "",
    color: "",
    form: false,
    editform: false,
    search: "",
    confirmDelete: false,
    itemsRouter: [
      {
        text: "Dashboard",
        disabled: false,
        color: "red",
        href: "/dashboard",
      },
      {
        text: "Plants",
        disabled: true,
        href: "/plants",
      },
    ],
    headers: [
      { text: "Name", value: "buildingName", sortable: true },
      { text: "MAC address", value: "macAddress", sortable: true },
      { text: "Action", value: "actions", sortable: false, align: "center" },
    ],
    dataAdd: {
      name: "",
      macAddress: "",
    },
    dataEdit: {
      id: null,
      name: null,
      macAddress: null,
    },
    idDelete: null,
  }),
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getBuilding() {
      return this.$store.getters.namesBuilding;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    if (
      this.getAccess.role === "Superadmin" &&
      this.getAccess.username === "pmAdmin"
    ) {
      this.loadPage();
    } else {
      this.$router.push("/");
    }
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode()) {
      //console.log("webapp is installed");
      this.isApp = true;
    }
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    onResize() {
      if (window.innerWidth < 960) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    loadPage() {
      this.$store.dispatch("getNames");
    },
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editform = false;
      this.dataAdd.name = "";
      this.dataAdd.macAddress = "";
    },
    delData(id) {
      this.confirmDelete = true;
      this.idDelete = id;
    },
    addPlant() {
      if (this.dataAdd.name == "" || this.dataAdd.macAddress == "") {
        this.text = "Error! please fill out this form";
        this.color = "red";
        this.snackbar = true;
      } else {
        this.$store
          .dispatch("createBuildings", this.dataAdd)
          .then((res) => {
            //console.log(res);
            if (res.status == 400) {
              this.snackbar = true;
              this.color = "error";
              this.text = res.data.error.message;
            } else if (res.status == "201") {
              this.color = "success";
              this.text = "Plant has been successfully saved.";
              this.snackbar = true;
              this.loadPage();
              this.closeDlg();
            } else {
              this.snackbar = true;
              this.color = "error";
              this.text = "Error! You cannot create a plant.";
            }
          })
          .catch((error) => {
            //console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.text = "Error! You cannot create a plant.";
          });
      }
    },
    delPlant() {
      this.$store
        .dispatch("deleteBuildings", this.idDelete)
        .then(() => {
          this.closeDlg();
          this.loadPage();
          this.color = "success";
          this.text = "Deleted! Plant has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          //console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this plant.";
          this.snackbar = true;
        });
    },
    editData(data) {
      //console.log(data);
      this.editform = true;
      this.dataEdit.id = data.id;
      this.dataEdit.name = data.buildingName;
      this.dataEdit.macAddress = data.macAddress;
    },
    editPlant() {
      if (this.dataEdit.name == "" || this.dataEdit.macAddress == "") {
        this.text = "Error! please fill out this form";
        this.color = "red";
        this.snackbar = true;
      } else {
        this.$store
          .dispatch("editBuildings", this.dataEdit)
          .then((res) => {
            if (res.status == 400) {
              this.snackbar = true;
              this.color = "error";
              this.text = res.data.error.message;
            } else if (res.status == "200") {
              this.loadPage();
              this.closeDlg();
              this.color = "success";
              this.text = "Success! Plant has been edited.";
              this.snackbar = true;
            } else {
              this.snackbar = true;
              this.color = "error";
              this.text = "Error! You cannot edit a plant.";
            }
          })
          .catch((error) => {
            //console.log(error);
            this.color = "error";
            this.text = " Error! You cannot edit this plant.";
            this.snackbar = true;
          });
      }
    },
  },
};
</script>

<style>
#btnsave:hover {
  color: green;
}
.add {
  position: fixed;
  bottom: 500px;
  right: 20px;
}
</style>

<template>
  <div>
    <v-bottom-navigation
      fixed
      color="white"
      background-color="#D32F2F"
      dark
      v-if="this.homePath"
    >
      <v-btn @click="dashboard()">
        <span>Dashboard</span>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>
      <v-btn @click="reports()">
        <span>Reports</span>
        <v-icon>mdi-file-document</v-icon>
      </v-btn>
      <v-btn v-if="getAccess.role === 'Superadmin'" @click="accounts()">
        <span>Setting</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation
      fixed
      color="white"
      background-color="#D32F2F"
      dark
      v-if="this.buildingPath"
    >
      <v-btn @click="dashboard()">
        <span>Dashboard</span>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>
      <v-btn @click="events()">
        <span>Events</span>
        <v-icon>mdi-bell-alert</v-icon>
      </v-btn>
      <v-btn @click="realtime()">
        <span>Realtime</span>
        <v-icon>mdi-message-text-clock</v-icon>
      </v-btn>
      <v-btn @click="message()">
        <span>Message</span>
        <v-icon>mdi-message-draw</v-icon>
      </v-btn>
      <v-btn @click="word()" v-if="getAccess.role === 'Superadmin'">
        <span>Key Word</span>
        <v-icon>mdi-form-textbox</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation
      fixed
      color="white"
      background-color="#D32F2F"
      dark
      v-if="this.adminPath"
    >
      <v-btn @click="dashboard()">
        <span>Dashboard</span>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>
      <v-btn v-if="getAccess.role === 'Superadmin'" @click="accounts()">
        <span>Accounts</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn v-if="getAccess.role === 'Superadmin'" @click="line()">
        <span>Line</span>
        <font-awesome-icon
          icon="fa-brands fa-line fa-lg"
          color="white"
          style="font-size: 23px"
          class="ml-1"
        />
      </v-btn>
      <v-btn
        v-if="
          getAccess.role === 'Superadmin' && getAccess.username === 'pmAdmin'
        "
        @click="plants()"
      >
        <span>Plants</span>
        <v-icon>mdi-factory</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
export default {
  created() {
    this.checkManu();
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
  },
  data() {
    return {
      homePath: false,
      buildingPath: false,
      adminPath: false,
      path: null,
    };
  },
  watch: {
    $route(to, from) {
      if (
        to.name.search("Events") === 0 ||
        to.name.search("SetMessages") === 0 ||
        to.name.search("SetWords") === 0 ||
        to.name.search("RealTime") === 0
      ) {
        this.buildingPath = true;
        this.homePath = false;
        this.adminPath = false;
      } else if (
        to.name.search("Dashboard") === 0 ||
        to.name.search("Reports") === 0
      ) {
        this.homePath = true;
        this.adminPath = false;
        this.buildingPath = false;
      } else {
        this.adminPath = true;
        this.buildingPath = false;
        this.homePath = false;
      }
    },
  },
  methods: {
    dashboard() {
      this.$router.push("/dashboard");
    },
    reports() {
      this.$router.push("/reports");
    },
    accounts() {
      this.$router.push("/accounts");
    },
    events() {
      this.$router.push("/plant/events");
    },
    message() {
      this.$router.push("/plant/setMessages");
    },
    word() {
      this.$router.push("/plant/setWords");
    },
    line() {
      this.$router.push("/lineConfig");
    },
    plants() {
      this.$router.push("/plants");
    },
    realtime() {
      this.$router.push("/plant/realtime");
    },
    checkManu() {
      this.path = this.$router.currentRoute.name;
      //console.log(this.path);
      if (
        this.path.search("Events") === 0 ||
        this.path.search("SetMessages") === 0 ||
        this.path.search("SetWords") === 0 ||
        this.path.search("Realtime") === 0
      ) {
        this.buildingPath = true;
        this.homePath = false;
        this.adminPath = false;
      } else if (
        this.path.search("Dashboard") === 0 ||
        this.path.search("Reports") === 0
      ) {
        this.homePath = true;
        this.adminPath = false;
        this.buildingPath = false;
      } else {
        this.adminPath = true;
        this.buildingPath = false;
        this.homePath = false;
      }
    },
  },
};
</script>
<style></style>

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome' // Integration
Vue.use(Vuetify);
Vue.component('font-awesome-icon', FontAwesomeIcon) // add it to vue

export default new Vuetify({
    icons: {
      iconfont: 'mdiSvg' ||  'fa' || 'fa4' || 'faSvg'
    },
  })

import axios from "axios";
import authHeader from "../services/auth-header";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const apiURL = process.env.VUE_APP_API_URL + "users/";
class AuthService {
  login(user) {
    return axios
      .post(apiURL + "login", {
        username: user.username,
        password: user.password,
      })
      .then((res) => {
        //console.log(JSON.stringify(res.data))
        if (res.data.accessToken) {
          Cookies.set("user", JSON.stringify(res.data));
          return res.data;
        }
      });
  }
  logout() {
    Cookies.remove("user");
    Cookies.remove("nameBuliding");
    Cookies.remove("idBuliding");
    Cookies.remove("lastPath");
  }
  resetPassword(payload) {
    return axios.put(
      apiURL + "resetPassword/" + payload.id,
      {
        newPassword: payload.newPassword,
      },
      { headers: authHeader() }
    );
  }
  changePassword(payload) {
    return axios.put(
      apiURL + "changePassword/" + payload.id,
      {
        newPassword: payload.newPassword,
        password: payload.password,
      },
      { headers: authHeader() }
    );
  }
  jwtDecode() {
    let user = Cookies.get("user");
    if (user != undefined) {
      const userDecode = JSON.parse(Cookies.get("user"));
      return jwt_decode(userDecode.accessToken);
    }
  }
}
export default new AuthService();

import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { Simplert } from "vue2-simplert-plugin";
import Notifications from 'vue-notification'
import "vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css";
//VueSocketIO
import * as io from "socket.io-client";
import VueSocketIO from "vue-socket.io";
const socketURL = process.env.VUE_APP_SOCKET_URL;
Vue.use(VueSocketIO, io(socketURL), store);
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
//excel
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
/* import specific icons */
import { faLine } from "@fortawesome/free-brands-svg-icons";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* add icons to the library */
library.add(faLine);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(Simplert);
Vue.use(Notifications)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { buildings } from "./buildings.module";
import { events } from "./events.module";
import { reports } from "./reports.module";
import { accounts } from "./accounts.module";
import { settings } from "./settings.module";
import { lines } from "./lines.module";
import { messages } from "./messages.module";
import { words } from "./words.module";
import { noti } from "./notifications.module";
import { logs } from "./logs.module";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    settings,
    buildings,
    noti,
    events,
    reports,
    accounts,
    messages,
    lines,
    words,
    logs,
  },
});

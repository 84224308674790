<template>
  <div v-resize="onResize">
    <v-container fluid>
      <v-breadcrumbs :items="itemsRouter">
        <template v-slot:divider>
          <v-icon size="25">mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
            <span :style="`color: ${item.color}`">
              {{ item.text.toUpperCase() }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card id="card" class="mx-6 mb-10">
        <v-card-title>
          Line Token
          <v-spacer></v-spacer>
          <v-text-field
            ref="search"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            class="shrink"
            style="width: 300px"
            color="#D32F2F"
          ></v-text-field>
        </v-card-title>
        <!-- <v-row align="center" justify="end" class="ml-1">
          <v-col cols="4" lg="2" md="3" sm="4" class="ma-0"> Add Line: </v-col>
          <v-col cols="8" lg="3" md="3" sm="8" align="end">
            <v-row>
              <v-btn color="primary" class="mr-3" @click="addLineAuto()"
                >Auto</v-btn
              >
              <v-btn color="primary" class="mr-3" @click="form = true"
                >Manual</v-btn
              >
            </v-row>
          </v-col>
        </v-row> -->

        <v-data-table
          item-key="id"
          :search="search"
          :headers="headers"
          :items="getLines"
          :items-per-page="5"
        >
          <template v-slot:[`item.actions`]="{ item }" v-if="!isMobile">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  @click="editLineData(item)"
                  color="success"
                  icon
                >
                  <v-icon>mdi-pencil</v-icon></v-btn
                >
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  @click="delData(item._id)"
                  color="error"
                  icon
                >
                  <v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
              <span>Edit</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }" v-else>
            <v-btn @click="editLineData(item)" color="success" icon>
              <v-icon>mdi-pencil</v-icon></v-btn
            >

            <v-btn @click="delData(item._id)" color="error" icon>
              <v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
        <v-btn
          style="position: fixed; right: 0px"
          :style="isMobile || isApp ? 'bottom: 60px;' : 'bottom: 5px;'"
          class="mx-8"
          fixed
          fab
          color="#D32F2F"
          dark
          absolute
          bottom
          right
          dense
          floating
          @click="form = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-dialog v-model="form" persistent max-width="500px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h5">Manual Add Line</span></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="addLine()"
                  @keyup.native.enter="addLine()"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-text-field
                      name="Line Name"
                      label="Line Name*"
                      :rules="[(v) => !!v || 'Line Name is required']"
                      required
                      v-model="lineData.groupName"
                      color="#000"
                    ></v-text-field>
                    <v-text-field
                      name="Token"
                      label="Token*"
                      :rules="[(v) => !!v || 'Token is required']"
                      required
                      v-model="lineData.token"
                      color="#000"
                    ></v-text-field>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn id="btnsave" color="success" text @click="addLine()">
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog v-model="editform" persistent max-width="500px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h5">Edit Config Line</span></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="editLine()"
                  @keyup.native.enter="editLine()"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-text-field
                      name="Line Name"
                      label="Line Name*"
                      :rules="[(v) => !!v || 'Line Name is required']"
                      required
                      v-model="dataEdit.groupName"
                      color="#000"
                    ></v-text-field>
                    <v-text-field
                      name="Token"
                      label="Token*"
                      :rules="[(v) => !!v || 'Token is required']"
                      required
                      v-model="dataEdit.token"
                      color="#000"
                    ></v-text-field>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn id="btnsave" color="success" text @click="editLine()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog v-model="confirmDelete" max-width="300">
          <v-card>
            <v-card-title primary-title> Confirm Delete </v-card-title>

            <v-card-text class="body">
              Are you sure to delete this config line ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>

              <v-btn color="error" text @click="delLine()"> Confirm </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
      <v-snackbar v-model="snackbar" buttom left :color="color" timeout="1500">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      isApp: false,
      form: false,
      editform: false,
      search: null,
      lineData: {
        groupName: "",
        token: "",
      },
      dataEdit: {
        id: null,
        groupName: null,
        token: null,
      },
      idDelete: null,
      color: null,
      text: null,
      snackbar: false,
      confirmDelete: false,
      headers: [
        { text: "Group Name", value: "groupName", sortable: true },
        { text: "Token", value: "token", sortable: true },
        { text: "Action", value: "actions", sortable: false, align: "center" },
      ],
      itemsRouter: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard",
          color: "red",
        },
        {
          text: "Line",
          disabled: true,
          href: "/lineConfig",
        },
      ],
    };
  },
  computed: {
    getLines() {
      return this.$store.getters.allLines;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser || this.getAccess.role != "Superadmin") {
      this.$router.push("/");
    } else {
      this.loadLine();
    }
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode()) {
      //console.log("webapp is installed");
      this.isApp = true;
    }
  },
  methods: {
    onResize() {
      if (window.innerWidth < 960) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    loadLine() {
      this.$store.dispatch("getLines");
    },
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editform = false;
      this.lineData.groupName = "";
      this.lineData.token = "";
    },
    delData(id) {
      this.confirmDelete = true;
      this.idDelete = id;
    },
    addLineAuto() {},
    addLine() {
      if (this.lineData.groupName == "" || this.lineData.token == "") {
        this.text = "Error! please fill out this form";
        this.color = "red";
        this.snackbar = true;
      } else {
        this.$store
          .dispatch("createLine", this.lineData)
          .then((res) => {
            if (res.status == 201) {
              this.closeDlg();
              this.loadLine();
              this.color = "success";
              this.text = "Config Line  has been successfully saved.";
              this.snackbar = true;
            } else if (res.status == 400) {
              this.snackbar = true;
              this.color = "error";
              this.text = res.data.error.message;
            }
          })
          .catch((error) => {
            //console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.text = "Error! You cannot create config line .";
          });
      }
    },
    editLineData(data) {
      this.editform = true;
      this.dataEdit.id = data._id;
      this.dataEdit.groupName = data.groupName;
      this.dataEdit.token = data.token;
    },
    editLine() {
      if (this.dataEdit.groupName == "" || this.dataEdit.token == "") {
        this.text = "Error! please fill out this form";
        this.color = "red";
        this.snackbar = true;
      } else {
        this.$store
          .dispatch("editLine", this.dataEdit)
          .then((res) => {
            //console.log(res);
            if (res.status == 200) {
              this.loadLine();
              this.closeDlg();
              this.color = "success";
              this.text = "Success! Config Line has been edited.";
              this.snackbar = true;
            } else if (res.status == 400) {
              this.snackbar = true;
              this.color = "error";
              this.text = res.data.error.message;
            }
          })
          .catch((error) => {
            this.color = "error";
            this.text = " Error! You cannot edit this config Line.";
            this.snackbar = true;
          });
      }
    },
    delLine() {
      this.$store
        .dispatch("deleteLine", this.idDelete)
        .then((res) => {
          if (res.status == 200) {
            this.closeDlg();
            this.loadLine();
            this.color = "success";
            this.text = "Deleted! Config Line has been deleted.";
            this.snackbar = true;
          } else if (res.status == 400) {
            this.snackbar = true;
            this.color = "error";
            this.text = res.data.error.message;
          }
        })
        .catch((error) => {
          //console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this config Line.";
          this.snackbar = true;
        });
    },
  },
};
</script>

<style>
</style>
<template>
  <div v-resize="onResize">
    <v-container fluid>
      <v-breadcrumbs :items="itemsRouter">
        <template v-slot:divider>
          <v-icon size="25">mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
            <span :style="`color: ${item.color}`">
              {{ item.text.toUpperCase() }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card id="card" class="mx-6 mb-5">
        <v-card-title>
          Accounts
          <v-spacer></v-spacer>
          <v-text-field
            ref="search"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            class="shrink"
            style="width: 300px"
            color="#D32F2F"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          item-key="id"
          :search="search"
          :headers="headers"
          :items="getUsers"
          :items-per-page="5"
        >
          <template v-slot:[`item.actions`]="{ item }" v-if="!isMobile">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  @click="resetPasswordData(item._id)"
                  color="orange"
                  icon
                  ><v-icon>mdi-key</v-icon></v-btn
                >
              </template>
              <span>Reset Password</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  @click="editUserData(item)"
                  color="success"
                  icon
                >
                  <v-icon>mdi-pencil</v-icon></v-btn
                >
              </template>
              <span>Edit Profile</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  @click="delUserData(item._id)"
                  color="error"
                  icon
                >
                  <v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }" v-else>
            <v-btn @click="resetPasswordData(item._id)" color="orange" icon
              ><v-icon>mdi-key</v-icon></v-btn
            >

            <v-btn @click="editUserData(item)" color="success" icon>
              <v-icon>mdi-pencil</v-icon></v-btn
            >

            <v-btn @click="delUserData(item._id)" color="error" icon>
              <v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
        <v-btn
          style="position: fixed; right: 0px"
          :style="isMobile || isApp ? 'bottom: 60px;' : 'bottom: 5px;'"
          class="mx-8"
          fixed
          fab
          color="#D32F2F"
          dark
          absolute
          bottom
          right
          dense
          floating
          @click="form = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-dialog v-model="form" persistent max-width="500px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h4">Create Account</span></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="addUser()"
                  @keyup.native.enter="addUser()"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-text-field
                      name="name"
                      label="Name*"
                      v-model="userData.name"
                      :rules="[(v) => !!v || 'Name is required']"
                      required
                      color="#000"
                      type="text"
                    ></v-text-field>

                    <v-text-field
                      type="text"
                      name="username"
                      label="Username*"
                      v-model="userData.username"
                      :rules="[(v) => !!v || 'Username is required']"
                      required
                      color="#000"
                      @keypress="isLetter($event)"
                    ></v-text-field>

                    <v-text-field
                      :type="hidePassword ? 'password' : 'text'"
                      :append-icon="
                        hidePassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                      "
                      name="password"
                      label="Password*"
                      v-model="userData.password"
                      @click:append="hidePassword = !hidePassword"
                      :rules="[(v) => !!v || 'Password is required']"
                      required
                      color="#000"
                      @keypress="isLetter($event)"
                    ></v-text-field>

                    <v-select
                      :items="['Admin', 'Superadmin']"
                      name="role"
                      label="Role*"
                      item-value="id"
                      v-model="userData.role"
                      required
                      color="#000"
                      item-color="#D32F2F"
                    ></v-select>
                    <v-select
                      :items="getName"
                      item-value="id"
                      item-text="buildingName"
                      label="Select Plant*"
                      v-model="userData.building"
                      color="#000"
                      v-if="userData.role === 'Admin'"
                      required
                      item-color="#D32F2F"
                    >
                    </v-select>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn color="success" id="btnsave" text @click="addUser()">
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <v-dialog v-model="editform" persistent max-width="500px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h5">Edit Profile</span></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="editUser()"
                  @keyup.native.enter="editUser()"
                  ref="userDataEdit"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-text-field
                      name="name"
                      label="Name*"
                      v-model="userDataEdit.name"
                      :rules="[(v) => !!v || 'Name is required']"
                      required
                      color="#000"
                    ></v-text-field>
                    <v-text-field
                      name="username"
                      label="Username*"
                      v-model="userDataEdit.username"
                      :rules="[(v) => !!v || 'Username is required']"
                      required
                      color="#000"
                      @keypress="isLetter($event)"
                    ></v-text-field>
                    <v-select
                      :items="['Admin', 'Superadmin']"
                      name="role"
                      label="Role*"
                      v-model="userDataEdit.role"
                      color="#000"
                      item-color="#D32F2F"
                      required
                    ></v-select>
                    <v-select
                      :items="getName"
                      item-value="id"
                      item-text="buildingName"
                      label="Select Plant*"
                      v-model="userDataEdit.building"
                      color="#000"
                      v-if="userDataEdit.role === 'Admin'"
                      item-color="#D32F2F"
                    >
                    </v-select>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn id="btnsave" color="success" text @click="editUser()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <v-dialog v-model="resetPW" max-width="400px">
          <v-card>
            <v-card-title primary-title> Reset password</v-card-title>
            <v-card-text>
              <v-form
                @submit.prevent="resetPasswords()"
                @keyup.native.enter="resetPasswords()"
                ref="userData"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-text-field
                  :type="hideNewPassword ? 'password' : 'text'"
                  :append-icon="
                    hideNewPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                  "
                  name="new password"
                  label="New Password*"
                  hide-details="auto"
                  v-model="password.new"
                  @click:append="hideNewPassword = !hideNewPassword"
                  :rules="[(v) => !!v || 'New Password is required']"
                  required
                  color="#000"
                  @keypress="isLetter($event)"
                ></v-text-field>
                <v-text-field
                  :type="hideConfirmPassword ? 'password' : 'text'"
                  :append-icon="
                    hideConfirmPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                  "
                  name="confirm password"
                  label="Confirm Password*"
                  hide-details="auto"
                  v-model="password.confirm"
                  @click:append="hideConfirmPassword = !hideConfirmPassword"
                  :rules="[(v) => !!v || 'Confirm Password is required']"
                  required
                  color="#000"
                  @keypress="isLetter($event)"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn
                id="btnsave"
                color="success"
                text
                @click="resetPasswords()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="confirmDelete" max-width="300">
          <v-card>
            <v-card-title primary-title> Confirm Delete </v-card-title>

            <v-card-text class="body">
              Are you sure to delete this user ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>

              <v-btn color="error" text @click="delUser()"> Confirm </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
      <v-snackbar v-model="snackbar" buttom left :color="color" timeout="1500">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "accounts",
  data: () => ({
    isMobile: false,
    isApp: false,
    hidePassword: true,
    hideNewPassword: true,
    hideConfirmPassword: true,
    resetPW: false,
    editform: false,
    snackbar: false,
    text: null,
    color: null,
    form: false,
    search: null,
    confirmDelete: false,
    itemsRouter: [
      {
        text: "Dashboard",
        disabled: false,
        color: "red",
        href: "/dashboard",
      },
      {
        text: "Accounts",
        disabled: true,
        href: "/accounts",
      },
    ],
    headers: [
      { text: "Username", value: "username", sortable: true },
      { text: "Name", value: "name", sortable: true },
      { text: "Role", value: "role", sortable: true },
      { text: "Plant", value: "buildingName", sortable: true },
      { text: "Action", value: "actions", sortable: false, align: "center" },
    ],
    userData: {
      name: "",
      username: "",
      password: "",
      role: "",
      building: "",
      buildingName: "",
    },
    userDataEdit: {
      id: "",
      name: "",
      username: "",
      role: "",
      building: "",
      buildingName: "",
    },
    resetData: {
      id: "",
      newPassword: "",
    },
    password: {
      id: "",
      new: "",
      confirm: "",
    },
  }),
  computed: {
    getUsers() {
      return this.$store.getters.allUsers;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getBuilding() {
      return this.$store.getters.namesBuilding;
    },
    getName() {
      return this.$store.getters.namesBuilding;
    },
  },
  mounted() {
    if (!this.currentUser || this.getAccess.role != "Superadmin") {
      this.$router.push("/");
    } else {
      this.loadUser();
    }
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode()) {
      //console.log("webapp is installed");
      this.isApp = true;
    }
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    onResize() {
      if (window.innerWidth < 960) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    loadUser() {
      this.$store.dispatch("getUsers");
      this.$store.dispatch("getNames");
    },
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editform = false;
      this.resetPW = false;
      this.userData.name = "";
      this.userData.username = "";
      this.userData.password = "";
      this.userData.building = "";
      this.userData.buildingName = "";
      this.userData.role = "";
      this.password.new = null;
      this.password.confirm = null;
    },
    delUserData(id) {
      this.confirmDelete = true;
      this.userDelId = id;
    },
    addUser() {
      if (
        this.userData.name == "" ||
        this.userData.username == "" ||
        this.userData.password == "" ||
        this.userData.role == ""
      ) {
        this.text = "Error! please fill out this form";
        this.color = "red";
        this.snackbar = true;
      } else if (
        /^[A-Za-z0-9]+$/.test(this.userData.username) &&
        /^[A-Za-z0-9]+$/.test(this.userData.password)
      ) {
        if (this.userData.role != "Superadmin") {
          if (this.userData.building == "") {
            this.text = "Error! please fill out this form";
            this.color = "red";
            this.snackbar = true;
          } else {
            const index = this.getName.findIndex(
              (x) => x.id === this.userData.building
            );
            this.userData.buildingName = this.getName[index].buildingName;
            this.$store
              .dispatch("createUsers", this.userData)
              .then((res) => {
                if (res.status == 400) {
                  this.snackbar = true;
                  this.color = "error";
                  this.text = res.data.error.message;
                  this.userData.username = "";
                } else if (res.status == 201) {
                  this.loadUser();
                  this.color = "success";
                  this.text = "Account has been successfully saved.";
                  this.snackbar = true;
                  this.closeDlg();
                } else {
                  this.snackbar = true;
                  this.color = "error";
                  this.text = "Error! You cannot create an account.";
                }
              })
              .catch((error) => {
                //console.log(error);
                this.snackbar = true;
                this.color = "error";
                this.text = "Error! You cannot create an account.";
              });
          }
        } else {
          this.$store
            .dispatch("createUsers", this.userData)
            .then((res) => {
              if (res.status == 400) {
                this.snackbar = true;
                this.color = "error";
                this.text = res.data.error.message;
                this.userData.username = "";
              } else if (res.status == 201) {
                this.loadUser();
                this.color = "success";
                this.text = "Account has been successfully saved.";
                this.snackbar = true;
                this.closeDlg();
              } else {
                this.snackbar = true;
                this.color = "error";
                this.text = "Error! You cannot create an account.";
              }
            })
            .catch((error) => {
              //console.log(error);
              this.snackbar = true;
              this.color = "error";
              this.text = "Error! You cannot create an account.";
            });
        }
      } else {
        this.text = "Error! Usernames and Password can only use A-Z a-z 0-9";
        this.color = "red";
        this.snackbar = true;
      }
    },
    delUser() {
      this.$store
        .dispatch("deleteUsers", this.userDelId)
        .then(() => {
          this.closeDlg();
          this.loadUser();
          this.color = "success";
          this.text = "Deleted! Account has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          //console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this account.";
          this.snackbar = true;
        });
    },
    editUserData(data) {
      this.editform = true;
      this.userDataEdit.id = data._id;
      this.userDataEdit.name = data.name;
      this.userDataEdit.username = data.username;
      this.userDataEdit.role = data.role;
      this.userDataEdit.building = data.building;
    },
    editUser() {
      if (
        this.userDataEdit.name == "" ||
        this.userDataEdit.username == "" ||
        this.userDataEdit.role == ""
      ) {
        this.text = "Error! please fill out this form";
        this.color = "red";
        this.snackbar = true;
      } else if (/^[A-Za-z0-9]+$/.test(this.userDataEdit.username)) {
        if (this.userDataEdit.role != "Superadmin") {
          if (this.userDataEdit.building == "") {
            this.text = "Error! please fill out this form";
            this.color = "red";
            this.snackbar = true;
          } else {
            const index = this.getName.findIndex(
              (x) => x.id === this.userDataEdit.building
            );
            this.userDataEdit.buildingName = this.getName[index].buildingName;
            this.$store
              .dispatch("editUsers", this.userDataEdit)
              .then((res) => {
                if (res.status == 400) {
                  this.snackbar = true;
                  this.color = "error";
                  this.text = res.data.error.message;
                  this.userDataEdit.username = "";
                } else if (res.status == 200) {
                  this.loadUser();
                  this.closeDlg();
                  this.color = "success";
                  this.text = "Success! Account has been edited.";
                  this.snackbar = true;
                } else {
                  this.snackbar = true;
                  this.color = "error";
                  this.text = "Error! You cannot edit an account.";
                }
              })
              .catch((error) => {
                //console.log(error);
                this.color = "error";
                this.text = " Error! You cannot edit this account.";
                this.snackbar = true;
              });
          }
        } else {
          this.$store
            .dispatch("editUsers", this.userDataEdit)
            .then((res) => {
              if (res.status == 400) {
                this.snackbar = true;
                this.color = "error";
                this.text = res.data.error.message;
                this.userDataEdit.username = "";
              } else if (res.status == 200) {
                this.loadUser();
                this.closeDlg();
                this.color = "success";
                this.text = "Success! Account has been edited.";
                this.snackbar = true;
              } else {
                this.snackbar = true;
                this.color = "error";
                this.text = "Error! You cannot edit an account.";
              }
            })
            .catch((error) => {
              //console.log(error);
              this.color = "error";
              this.text = " Error! You cannot edit this account.";
              this.snackbar = true;
            });
        }
      } else {
        this.text = "Error! Usernames can only use A-Z a-z 0-9";
        this.color = "red";
        this.snackbar = true;
      }
    },
    resetPasswordData(id) {
      this.resetPW = true;
      this.password.id = id;
    },
    resetPasswords() {
      if (
        this.password.new === "" ||
        this.password.confirm == "" ||
        this.password.new === null ||
        this.password.confirm == null
      ) {
        this.color = "error";
        this.text = "Error! please fill out this form";
        this.snackbar = true;
      } else if (
        /^[A-Za-z0-9]+$/.test(this.password.new) &&
        /^[A-Za-z0-9]+$/.test(this.password.confirm)
      ) {
        if (this.password.new === this.password.confirm) {
          this.resetData.id = this.password.id;
          this.resetData.newPassword = this.password.new;
          //console.log(this.resetData);
          this.$store
            .dispatch("resetPassword", this.resetData)
            .then(() => {
              this.loadUser();
              this.password.new = "";
              this.password.confirm = "";
              this.color = "success";
              this.text = "Reset Password Complete.";
              this.resetPW = false;
              this.snackbar = true;
            })
            .catch((error) => {
              //console.log(error);
              this.color = "error";
              this.text = " Error! You cannot reset password this account.";
              this.snackbar = true;
            });
        } else {
          this.color = "error";
          this.text = "New Password and Confirm Password do not match";
          this.snackbar = true;
        }
      } else {
        this.text = "Error! Password can only use A-Z a-z 0-9";
        this.color = "red";
        this.snackbar = true;
      }
    },
  },
};
</script>

<style>
#btnsave:hover {
  color: green;
}
.add {
  position: fixed;
  bottom: 500px;
  right: 20px;
}
</style>

import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "users/";
import authHeader from "../services/auth-header";
export const accounts = {
  state: {
    users: [],
  },
  getters: {
    allUsers: (state) => state.users,
  },
  mutations: {
    setUser(state, user) {
      state.users = user;
    },
  },
  actions: {
    async getUsers(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data);
          context.commit("setUser", res.data.data);
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    async createUsers(context, data) {
      //console.log(data);
      return axios
        .post(apiURL, data, { headers: authHeader() })
        .then((res) => {
          //console.log(res)
          return res;
        })
        .catch((error) => {
          //console.log(error.response);
          return error.response;
        });
    },
    async deleteUsers(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          //console.log("Delete is successfully");
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    async editUsers(constext, data) {
      //console.log(data);
      return axios
        .put(apiURL + data.id, data, { headers: authHeader() })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          //console.log(error.response);
          return error.response;
        });
    },
    async resetPassword(context, data) {
      //console.log(data);
      return axios
        .put(
          apiURL + "resetPassword/" + data.id,
          {
            id: data,
            newPassword: data.newPassword,
          },
          { headers: authHeader() }
        )
        .then(() => {
          //console.log("Reset password successfully");
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
  },
};

import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Reports from "../views/Reports.vue";
import Accounts from "../views/Accounts.vue";
import Events from "../views/Events.vue";
import SetMessages from "../views/SetMessages.vue";
import RealTime from "../views/Realtime.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Line from "../views/LineConfig.vue";
import Plants from "../views/Plants.vue";
import SetWords from "../views/Words.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: ResetPassword,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/plant/events/:id",
    name: "Events",
    component: Events,
  },
  {
    path: "/plant/realtime",
    name: "RealTime",
    component: RealTime,
  },
  {
    path: "/plant/setMessages",
    name: "SetMessages",
    component: SetMessages,
  },
  {
    path: "/plant/setWords",
    name: "SetWords",
    component: SetWords,
  },
  {
    path: "/lineConfig",
    name: "LineConfig",
    component: Line,
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: Accounts,
  },
  {
    path: "/plants",
    name: "Plants",
    component: Plants,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

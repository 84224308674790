<template>
  <div v-resize="onResize" v-if="this.getWords.word != undefined">
    <v-container fluid>
      <v-breadcrumbs :items="itemsRouter">
        <template v-slot:divider>
          <v-icon size="25">mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
            <span :style="`color: ${item.color}`">
              {{ item.text.toUpperCase() }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card id="card" class="mx-6 mb-10">
        <v-card-title>
          <v-row align="center">
            <v-col cols="12" xl="3" l="3" md="3" sm="6"> Keyword </v-col>
          </v-row>
        </v-card-title>
        <v-simple-table
          v-if="this.getWords.word != undefined && !this.isMobile"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Keyword On</th>
                <th class="text-left">Keyword Off</th>
                <th class="text-center" v-if="getAccess.username === 'pmAdmin'">
                  Time Out On (second)
                </th>
                <th class="text-center" v-if="getAccess.username === 'pmAdmin'">
                  Time Out Keyword (minute)
                </th>
                <th class="text-center">Setting</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 18%">Reset</td>
                <td style="width: 18%">{{ getWords.word.reset.on }}</td>
                <td style="width: 18%">{{ getWords.word.reset.off }}</td>
                <td
                  class="text-center"
                  style="width: 18%"
                  v-if="getAccess.username === 'pmAdmin'"
                >
                  {{ getWords.word.reset.timeout }}
                </td>
                <td
                  class="text-center"
                  style="width: 18%"
                  v-if="getAccess.username === 'pmAdmin'"
                >
                  {{ getWords.timeout.reset }}
                </td>
                <td v-if="!isMobile" class="text-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        @click="editData({ reset: getWords.word.reset })"
                        color="error"
                        icon
                      >
                        <v-icon>mdi-cog</v-icon></v-btn
                      >
                    </template>
                    <span>Setting</span>
                  </v-tooltip>
                </td>
                <td v-else class="text-center">
                  <v-btn
                    @click="editData({ reset: getWords.word.reset })"
                    color="error"
                    icon
                  >
                    <v-icon>mdi-cog</v-icon></v-btn
                  >
                </td>
              </tr>
              <tr>
                <td>Silence</td>
                <td>{{ getWords.word.silence.on }}</td>
                <td>{{ getWords.word.silence.off }}</td>
                <td class="text-center" v-if="getAccess.username === 'pmAdmin'">
                  {{ getWords.word.silence.timeout }}
                </td>
                <td class="text-center" v-if="getAccess.username === 'pmAdmin'">
                  {{ getWords.timeout.silence }}
                </td>
                <td v-if="!isMobile" class="text-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        @click="editData({ silence: getWords.word.silence })"
                        color="error"
                        icon
                      >
                        <v-icon>mdi-cog</v-icon></v-btn
                      >
                    </template>
                    <span>Setting</span>
                  </v-tooltip>
                </td>
                <td v-else class="text-center">
                  <v-btn
                    @click="editData({ silence: getWords.word.silence })"
                    color="error"
                    icon
                  >
                    <v-icon>mdi-cog</v-icon></v-btn
                  >
                </td>
              </tr>
              <tr>
                <td>Acknowledge</td>
                <td>{{ getWords.word.acknowledge.on }}</td>
                <td>{{ getWords.word.acknowledge.off }}</td>
                <td class="text-center" v-if="getAccess.username === 'pmAdmin'">
                  {{ getWords.word.acknowledge.timeout }}
                </td>
                <td class="text-center" v-if="getAccess.username === 'pmAdmin'">
                  {{ getWords.timeout.acknowledge }}
                </td>
                <td v-if="!isMobile" class="text-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          editData({ acknowledge: getWords.word.acknowledge })
                        "
                        color="error"
                        icon
                      >
                        <v-icon>mdi-cog</v-icon></v-btn
                      >
                    </template>
                    <span>Setting</span>
                  </v-tooltip>
                </td>
                <td v-else class="text-center">
                  <v-btn
                    @click="
                      editData({ acknowledge: getWords.word.acknowledge })
                    "
                    color="error"
                    icon
                  >
                    <v-icon>mdi-cog</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-simple-table v-if="getWords.word != undefined && this.isMobile">
          <template v-slot:default>
            <tbody>
              <tr class="v-data-table__mobile-table-row">
                <td class="v-data-table__mobile-row">
                  <div class="v-data-table__mobile-row__header">Name</div>
                  <div class="v-data-table__mobile-row__cell">Reset</div>
                </td>
                <td class="v-data-table__mobile-row">
                  <div class="v-data-table__mobile-row__header">
                    Key Word On
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{ getWords.word.reset.on }}
                  </div>
                </td>
                <td class="v-data-table__mobile-row">
                  <div class="v-data-table__mobile-row__header">
                    Key Word Off
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{ getWords.word.reset.off }}
                  </div>
                </td>
                <td
                  class="v-data-table__mobile-row"
                  v-if="getAccess.username === 'pmAdmin'"
                >
                  <div class="v-data-table__mobile-row__header">
                    Time Out On (second)
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{ getWords.word.reset.timeout }}
                  </div>
                </td>
                <td
                  class="v-data-table__mobile-row"
                  v-if="getAccess.username === 'pmAdmin'"
                >
                  <div class="v-data-table__mobile-row__header">
                    Time Out Keyword (minute)
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{ getWords.timeout.reset }}
                  </div>
                </td>
                <td class="v-data-table__mobile-row">
                  <div class="v-data-table__mobile-row__header">Setting</div>
                  <div class="v-data-table__mobile-row__cell">
                    <v-btn
                      @click="editData({ reset: getWords.word.reset })"
                      color="error"
                      icon
                    >
                      <v-icon>mdi-cog</v-icon></v-btn
                    >
                  </div>
                </td>
              </tr>
              <tr class="v-data-table__mobile-table-row">
                <td class="v-data-table__mobile-row">
                  <div class="v-data-table__mobile-row__header">Name</div>
                  <div class="v-data-table__mobile-row__cell">Silence</div>
                </td>
                <td class="v-data-table__mobile-row">
                  <div class="v-data-table__mobile-row__header">
                    Key Word On
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{ getWords.word.silence.on }}
                  </div>
                </td>
                <td class="v-data-table__mobile-row">
                  <div class="v-data-table__mobile-row__header">
                    Key Word Off
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{ getWords.word.silence.off }}
                  </div>
                </td>
                <td
                  class="v-data-table__mobile-row"
                  v-if="getAccess.username === 'pmAdmin'"
                >
                  <div class="v-data-table__mobile-row__header">
                    Time Out On (second)
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{ getWords.word.silence.timeout }}
                  </div>
                </td>
                <td
                  class="v-data-table__mobile-row"
                  v-if="getAccess.username === 'pmAdmin'"
                >
                  <div class="v-data-table__mobile-row__header">
                    Time Out (second)
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{ getWords.timeout.silence }}
                  </div>
                </td>
                <td class="v-data-table__mobile-row">
                  <div class="v-data-table__mobile-row__header">Setting</div>
                  <div class="v-data-table__mobile-row__cell">
                    <v-btn
                      @click="editData({ silence: getWords.word.silence })"
                      color="error"
                      icon
                    >
                      <v-icon>mdi-cog</v-icon></v-btn
                    >
                  </div>
                </td>
              </tr>
              <tr class="v-data-table__mobile-table-row">
                <td class="v-data-table__mobile-row">
                  <div class="v-data-table__mobile-row__header">Name</div>
                  <div class="v-data-table__mobile-row__cell">Acknowledge</div>
                </td>
                <td class="v-data-table__mobile-row">
                  <div class="v-data-table__mobile-row__header">
                    Key Word On
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{ getWords.word.acknowledge.on }}
                  </div>
                </td>
                <td class="v-data-table__mobile-row">
                  <div class="v-data-table__mobile-row__header">
                    Key Word Off
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{ getWords.word.acknowledge.off }}
                  </div>
                </td>
                <td
                  class="v-data-table__mobile-row"
                  v-if="getAccess.username === 'pmAdmin'"
                >
                  <div class="v-data-table__mobile-row__header">
                    Time Out On (second)
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{ getWords.word.acknowledge.timeout }}
                  </div>
                </td>
                <td
                  class="v-data-table__mobile-row"
                  v-if="getAccess.username === 'pmAdmin'"
                >
                  <div class="v-data-table__mobile-row__header">
                    Time Out Keyword (minute)
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{ getWords.timeout.acknowledge }}
                  </div>
                </td>
                <td class="v-data-table__mobile-row">
                  <div class="v-data-table__mobile-row__header">Setting</div>
                  <div class="v-data-table__mobile-row__cell">
                    <v-btn
                      @click="
                        editData({ acknowledge: getWords.timeout.acknowledge })
                      "
                      color="error"
                      icon
                    >
                      <v-icon>mdi-cog</v-icon></v-btn
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-dialog v-model="formEdit" persistent max-width="500px">
        <template>
          <v-card>
            <v-card-title
              ><span class="text-h5">Edit Keyword Setting</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="editWord()"
                @keyup.native.enter="editWord()"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-row align="center" justify="start">
                  <v-col cols="5" lg="4" md="4" sm="4">
                    <h3>{{ this.dataEdit.name }} On:</h3>
                  </v-col>
                  <v-col
                    cols="7"
                    lg="8"
                    md="8"
                    sm="8"
                    class="mb-0 pb-0"
                    v-if="dataEdit.name == 'Reset'"
                  >
                    <v-text-field
                      name="Key Word"
                      label="Key Word*"
                      :rules="[(v) => !!v || 'Keyword is required']"
                      required
                      v-model="dataEdit.word.reset.on"
                      color="#000"
                      type="text"
                      @keypress="isLetter($event)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="7"
                    lg="8"
                    md="8"
                    sm="8"
                    class="mb-0 pb-0"
                    v-if="dataEdit.name == 'Silence'"
                  >
                    <v-text-field
                      name="Key Word"
                      label="Key Word*"
                      :rules="[(v) => !!v || 'Keyword is required']"
                      required
                      v-model="dataEdit.word.silence.on"
                      type="text"
                      color="#000"
                      @keypress="isLetter($event)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="7"
                    lg="8"
                    md="8"
                    sm="8"
                    class="mb-0 pb-0"
                    v-if="dataEdit.name == 'Acknowledge'"
                  >
                    <v-text-field
                      name="Key Word"
                      label="Key Word*"
                      type="text"
                      :rules="[(v) => !!v || 'Keyword is required']"
                      required
                      v-model="dataEdit.word.acknowledge.on"
                      color="#000"
                      @keypress="isLetter($event)"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center" justify="start">
                  <v-col cols="5" lg="4" md="4" sm="4">
                    <h3>{{ this.dataEdit.name }} Off:</h3>
                  </v-col>
                  <v-col
                    cols="7"
                    lg="8"
                    md="8"
                    sm="8"
                    class="mb-0 pb-0"
                    v-if="dataEdit.name == 'Reset'"
                  >
                    <v-text-field
                      class="my-0 py-0"
                      name="Key Word"
                      label="Key Word*"
                      :rules="[(v) => !!v || 'Keyword is required']"
                      required
                      v-model="dataEdit.word.reset.off"
                      color="#000"
                      type="text"
                      @keypress="isLetter($event)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="7"
                    lg="8"
                    md="8"
                    sm="8"
                    class="mb-0 pb-0"
                    v-if="dataEdit.name == 'Silence'"
                  >
                    <v-text-field
                      class="my-0 py-0"
                      name="Key Word"
                      label="Key Word*"
                      :rules="[(v) => !!v || 'Keyword is required']"
                      required
                      v-model="dataEdit.word.silence.off"
                      type="text"
                      color="#000"
                      @keypress="isLetter($event)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="7"
                    lg="8"
                    md="8"
                    sm="8"
                    class="mb-0 pb-0"
                    v-if="dataEdit.name == 'Acknowledge'"
                  >
                    <v-text-field
                      class="my-0 py-0"
                      name="Key Word"
                      label="Key Word*"
                      type="text"
                      :rules="[(v) => !!v || 'Keyword is required']"
                      required
                      v-model="dataEdit.word.acknowledge.off"
                      color="#000"
                      @keypress="isLetter($event)"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  align="center"
                  justify="start"
                  v-if="getAccess.username === 'pmAdmin'"
                >
                  <v-col cols="5" lg="4" md="4" sm="4">
                    <h3>Time Out On :</h3>
                  </v-col>
                  <v-col
                    cols="7"
                    lg="8"
                    md="8"
                    sm="8"
                    class="mb-0 pb-0"
                    v-if="dataEdit.name == 'Reset'"
                  >
                    <v-text-field
                      class="my-0 py-0"
                      name="Time Out"
                      label="Time Out (second)*"
                      :rules="[(v) => !!v || 'Time Out is required']"
                      required
                      v-model="dataEdit.word.reset.timeout"
                      color="#000"
                      type="number"
                      @keypress="isNum($event)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="7"
                    lg="8"
                    md="8"
                    sm="8"
                    class="mb-0 pb-0"
                    v-if="dataEdit.name == 'Silence'"
                  >
                    <v-text-field
                      name="Time Out"
                      label="Time Out (second)*"
                      :rules="[(v) => !!v || 'Time Out is required']"
                      required
                      v-model="dataEdit.word.silence.timeout"
                      type="number"
                      color="#000"
                      @keypress="isNum($event)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="7"
                    lg="8"
                    md="8"
                    sm="8"
                    class="mb-0 pb-0"
                    v-if="dataEdit.name == 'Acknowledge'"
                  >
                    <v-text-field
                      name="Time Out"
                      label="Time Out (second)*"
                      type="number"
                      :rules="[(v) => !!v || 'Time Out is required']"
                      required
                      v-model="dataEdit.word.acknowledge.timeout"
                      color="#000"
                      @keypress="isNum($event)"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  align="center"
                  justify="start"
                  v-if="getAccess.username === 'pmAdmin'"
                >
                  <v-col cols="5" lg="4" md="4" sm="4">
                    <h3>Time Out Keyword :</h3>
                  </v-col>
                  <v-col
                    cols="7"
                    lg="8"
                    md="8"
                    sm="8"
                    class="mb-0 pb-0"
                    v-if="dataEdit.name == 'Reset'"
                  >
                    <v-text-field
                      class="my-0 py-0"
                      name="Time Out"
                      label="Time Out (minute)*"
                      :rules="[(v) => !!v || 'Time Out is required']"
                      required
                      v-model="dataEdit.timeout.reset"
                      color="#000"
                      type="number"
                      @keypress="isNum($event)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="7"
                    lg="8"
                    md="8"
                    sm="8"
                    class="mb-0 pb-0"
                    v-if="dataEdit.name == 'Silence'"
                  >
                    <v-text-field
                      name="Time Out"
                      label="Time Out (minute)*"
                      :rules="[(v) => !!v || 'Time Out is required']"
                      required
                      v-model="dataEdit.timeout.silence"
                      type="number"
                      color="#000"
                      @keypress="isNum($event)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="7"
                    lg="8"
                    md="8"
                    sm="8"
                    class="mb-0 pb-0"
                    v-if="dataEdit.name == 'Acknowledge'"
                  >
                    <v-text-field
                      name="Time Out"
                      label="Time Out (minute)*"
                      type="number"
                      :rules="[(v) => !!v || 'Time Out is required']"
                      required
                      v-model="dataEdit.timeout.acknowledge"
                      color="#000"
                      @keypress="isNum($event)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn id="btnsave" text @click="editWord()"> Submit </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-snackbar v-model="snackbar" buttom left :color="color" timeout="1500">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "words",
  data() {
    return {
      word: null,
      itemsRouter: [
        {
          text: "Dashboard",
          disabled: false,
          color: "red",
          href: "/dashboard",
        },
        {
          text: "Plant",
          disabled: true,
        },
      ],
      dataEdit: {
        id: null,
        name: null,
        word: {
          acknowledge: {
            on: null,
            off: null,
            timeout: null,
          },
          reset: {
            on: null,
            off: null,
            timeout: null,
          },
          silence: {
            on: null,
            off: null,
            timeout: null,
          },
        },
        timeout: {
          acknowledge: null,
          reset: null,
          silence: null,
        },
      },
      formEdit: false,
      formEditTime: false,
      snackbar: false,
      idBuliding: null,
      isMobile: false,
      isApp: false,
      text: "",
      color: "",
    };
  },
  computed: {
    getWords() {
      return this.$store.getters.allWords;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  async mounted() {
    this.idBuliding = Cookies.get("idBuliding");
    if (
      !this.currentUser ||
      this.getAccess.role != "Superadmin" ||
      this.idBuliding == null
    ) {
      this.$router.push("/");
    } else {
      await this.loadPage();
    }
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode()) {
      //console.log("webapp is installed");
      this.isApp = true;
    }
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9\s/]+$/.test(char)) return true;
      else e.preventDefault();
    },
    isNum(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    async loadPage() {
      this.idBuliding = Cookies.get("idBuliding");
      await this.$store.dispatch("getWords", this.idBuliding).then((res) => {
        //console.log(res);
        if (res.status == 200) {
          this.words = res.data.data;
          this.dataEdit.id = res.data.data.id;
        } else if (res.status == 400) {
          this.color = "error";
          this.text = res.data.error.message;
          this.snackbar = true;
        } else {
          this.color = "error";
          this.text = " Error! You cannot download Keywords.";
          this.snackbar = true;
        }
      });
    },
    onResize() {
      if (window.innerWidth < 960) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    closeDlg() {
      this.formEdit = false;
      this.formEditTime = false;
    },
    editData(data) {
      if (data.reset != undefined) {
        this.dataEdit.name = "Reset";
      } else if (data.acknowledge != undefined) {
        this.dataEdit.name = "Acknowledge";
      } else if (data.silence != undefined) {
        this.dataEdit.name = "Silence";
      }
      this.dataEdit.id = this.words.id;
      this.dataEdit.timeout.reset = this.words.timeout.reset;
      this.dataEdit.timeout.silence = this.words.timeout.silence;
      this.dataEdit.timeout.acknowledge = this.words.timeout.acknowledge;
      this.dataEdit.word.reset.on = this.words.word.reset.on;
      this.dataEdit.word.silence.on = this.words.word.silence.on;
      this.dataEdit.word.acknowledge.on = this.words.word.acknowledge.on;
      this.dataEdit.word.reset.off = this.words.word.reset.off;
      this.dataEdit.word.silence.off = this.words.word.silence.off;
      this.dataEdit.word.acknowledge.off = this.words.word.acknowledge.off;
      this.dataEdit.word.reset.timeout = this.words.word.reset.timeout;
      this.dataEdit.word.silence.timeout = this.words.word.silence.timeout;
      this.dataEdit.word.acknowledge.timeout =
        this.words.word.acknowledge.timeout;
      this.formEdit = true;
    },
    editWord() {
      //console.log(this.dataEdit);
      if (
        this.dataEdit.id == "" ||
        this.dataEdit.word.reset.on == "" ||
        this.dataEdit.word.silence.on == "" ||
        this.dataEdit.word.acknowledge.on == "" ||
        this.dataEdit.word.reset.off == "" ||
        this.dataEdit.word.silence.off == "" ||
        this.dataEdit.word.acknowledge.off == "" ||
        this.dataEdit.word.reset.timeout == "" ||
        this.dataEdit.word.silence.timeout == "" ||
        this.dataEdit.word.acknowledge.timeout == "" ||
        this.dataEdit.timeout.reset == "" ||
        this.dataEdit.timeout.silence == "" ||
        this.dataEdit.timeout.acknowledge == ""
      ) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! please fill out this form";
      } else if (
        /^[A-Za-z0-9\s/]+$/.test(this.dataEdit.word.reset.on) ||
        /^[A-Za-z0-9\s/]+$/.test(this.dataEdit.word.silence.on) ||
        /^[A-Za-z0-9\s/]+$/.test(this.dataEdit.word.acknowledge.on) ||
        /^[A-Za-z0-9\s/]+$/.test(this.dataEdit.word.reset.off) ||
        /^[A-Za-z0-9\s/]+$/.test(this.dataEdit.word.silence.off) ||
        /^[A-Za-z0-9\s/]+$/.test(this.dataEdit.word.acknowledge.off) ||
        /^[0-9]+$/.test(this.dataEdit.word.reset.timeout) ||
        /^[0-9]+$/.test(this.dataEdit.word.silence.timeout) ||
        /^[0-9]+$/.test(this.dataEdit.word.acknowledge.timeout) ||
        /^[0-9]+$/.test(this.dataEdit.timeout.reset) ||
        /^[0-9]+$/.test(this.dataEdit.timeout.silence) ||
        /^[0-9]+$/.test(this.dataEdit.timeout.acknowledge)
      ) {
        this.$store
          .dispatch("editWord", this.dataEdit)
          .then((res) => {
            //console.log(res);
            if (res.status == 200) {
              this.loadPage();
              this.closeDlg();
              this.color = "success";
              this.text = "Success! Keyword has been edited.";
              this.snackbar = true;
            } else if (res.status == 400) {
              this.color = "error";
              this.text = res.data.error.message;
              this.snackbar = true;
            } else {
              this.color = "error";
              this.text = " Error! You cannot edit this Keyword.";
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (!error) {
              this.text = "Error! You cannot edit this Keyword.";
            } else {
              this.text = error;
            }
            this.color = "error";
            this.snackbar = true;
          });
        this.$store
          .dispatch("editTime", this.dataEdit)
          .then((res) => {
            if (res.status == 200) {
              this.loadPage();
              this.closeDlg();
              this.color = "success";
              this.text = "Success! Time Out has been edited.";
              this.snackbar = true;
            } else if (res.status == 400) {
              this.color = "error";
              this.text = res.data.error.message;
              this.snackbar = true;
            } else {
              this.color = "error";
              this.text = " Error! You cannot edit this Keyword.";
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (!error) {
              this.text = "Error! You cannot edit this Keyword.";
            } else {
              this.text = error;
            }
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.snackbar = true;
        this.color = "error";
        this.text =
          "Error! Keyword can only use English and Timout can only use number";
      }
    },
  },
};
</script>

<style>
#btnsave:hover {
  color: green;
}
.add {
  position: fixed;
  bottom: 500px;
  right: 20px;
}
</style>

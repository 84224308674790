import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "words/";
import authHeader from "../services/auth-header";
export const words = {
  state: {
    words: {},
  },
  getters: {
    allWords: (state) => state.words,
  },
  mutations: {
    setWords(state, words) {
      state.words = words;
    },
  },
  actions: {
    async getWords(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data);
          context.commit("setWords", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error.response;
        });
    },
    editWord(constext, data) {
      console.log(data.word)
      return axios
        .put(
          apiURL + data.id,
          {
            word: data.word,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res)
          return res;
        })
        .catch((error) => {
          //console.log(error.response)
          return error.response;
        });
    },
    editTime(constext, data) {
      //console.log(data)
      return axios
        .put(
          apiURL +"timeout/"+ data.id,
          {
            timeout: {
              reset: parseInt(data.timeout.reset),
              silence: parseInt(data.timeout.silence),
              acknowledge: parseInt(data.timeout.acknowledge),
            },
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res)
          return res;
        })
        .catch((error) => {
          //console.log(error.response)
          return error.response;
        });
    },
  },
};

<template>
  <div>
    <v-container fluid>
      <v-breadcrumbs :items="itemsRouter">
        <template v-slot:divider>
          <v-icon size="25">mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
            <span :style="`color: ${item.color}`">
              {{ item.text.toUpperCase() }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card id="card" class="mx-6">
        <v-col cols="12">
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="getLogs"
            :items-per-page="5"
          >
          </v-data-table>
        </v-col>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    getLogs() {
      return this.$store.getters.allLogs;
    },
  },
  data() {
    return {
      color: null,
      loading: false,
      itemsRouter: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard",
          color: "red",
        },
        {
          text: "Plant",
          disabled: true,
        },
      ],
      name: null,
      id: null,
      headers: [
        {
          text: "Date",
          sortable: true,
          value: "dateStart",
          width: "15%",
        },
        { text: "Time", value: "timeStart", sortable: true, width: "15%" },
        {
          text: "Description",
          value: "description",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    if (!this.currentUser || this.id == null) {
      this.$router.push("/");
    } else {
      this.loadPage();
      this.$options.sockets.status = (data) => {
        if (data.event == true) {
          this.realtime();
        }
      };
    }
  },
  created() {
    if (this.$route.params.data != null) {
      this.name = this.$route.params.data.buildingName;
      this.id = this.$route.params.data.id;
      Cookies.set("nameBuliding", this.name);
      Cookies.set("idBuliding", this.id);
    } else {
      this.name = Cookies.get("nameBuliding");
      this.id = Cookies.get("idBuliding");
    }
  },
  methods: {
    realtime() {
      this.loadPage();
    },
    loadPage: async function () {
      this.loading = true
      this.name = Cookies.get("nameBuliding");
      this.id = Cookies.get("idBuliding");
      this.$store.dispatch("getLogs", this.id).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#card {
  border-top: 5px solid;
  border-top-color: #d32f2f;
}
</style>

import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "lines/";
import authHeader from "../services/auth-header";
export const lines = {
  state: {
    lines: [],
  },
  getters: {
    allLines: (state) => state.lines,
  },
  mutations: {
    setLines(state, lines) {
      state.lines = lines;
    },
  },
  actions: {
    async getLines(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data);
          context.commit("setLines", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    createLine(context, data) {
      //console.log(data);
      return axios
        .post(
          apiURL,
          {
            groupName: data.groupName,
            token: data.token,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error.response;
        });
    },
    deleteLine(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then((res) => {
          //console.log("Delete is successfully");
          return res
        })
        .catch((error) => {
          //console.log(error);
          return error.response;
        });
    },
    editLine(constext, data) {
      //console.log(data);
      return axios
        .put(
          apiURL + data.id,
          {
            groupName: data.groupName,
            token: data.token,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          //console.log(error)
          return error.response;
        });
    },
  },
};

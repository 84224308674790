import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "events/";
import authHeader from "../services/auth-header";
export const events = {
  state: {
    events: [],
    eventsAll: [],
    eventsBytype: [],
  },
  getters: {
    eventsBuliding: (state) => state.events,
    eventsBytype: (state) => state.eventsBytype,
    eventsAll: (state) => state.eventsAll,
  },
  actions: {
    setClear(context, id) {
      //console.log(id);
      return axios
        .post(apiURL + "clear", { building: id }, { headers: authHeader() })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error.response;
        });
    },
    getAllEvents({ commit }, data) {
      //console.log("getEvents");
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          //console.log(res);
          commit("getAllEvents", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    getEvents({ commit }, data) {
      //console.log("getEvents");
      if (data.type == "All") {
        data.type = "";
      }
      //console.log(data)
      return axios
        .post(
          apiURL,
          {
            building: data.id,
            typeSearch: data.type,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res.data.data);
          commit("getEvents", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    getEventsType({ commit }, data) {
      if (data.type == "All") {
        data.type = "";
      }
      //console.log("getEvents");
      return axios
        .post(
          apiURL,
          {
            building: data.id,
            typeSearch: data.type,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res.data.data);
          commit("getEventsType", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
  },
  mutations: {
    getEvents(state, events) {
      state.events = events;
    },
    getAllEvents(state, events) {
      state.eventsAll = events;
    },
    getEventsType(state, eventsBytype) {
      state.eventsBytype = eventsBytype;
    },
    SOCKET_STATUS(state, payload) {
      //console.log(this.state.auth.access);
      const user = this.state.auth.access;
      const data = payload[0];
      //console.log(data);
      if (data.building != undefined) {
        if (
          user.buildingName == data.buildingName ||
          user.role == "Superadmin"
        ) {
          let newArray = [data].concat(state.eventsAll);
          //console.log(newArray)
          state.eventsAll = newArray;
        }
      }
    },
  },
};

import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "notifications/";
import authHeader from "../services/auth-header";
export const noti = {
  state: {
    noti: [],
  },
  getters: {
    allNoti: (state) => state.noti,
  },
  mutations: {
    setNoti(state, noti) {
      state.noti = noti;
    },
    deleteNoti(state, data) {
      const i = state.noti.findIndex((noti) => noti._id == data._id);
      state.noti.splice(i, 1);
    },
    SOCKET_STATUS(state, payload) {
      //console.log(this.state.auth.access);
      const user = this.state.auth.access;
      const data = payload[0];
      if (data.building != undefined) {
        if (user.building == data.building || user.role == "Superadmin") {
          state.noti.push(data);
        }
      }
    },
  },
  actions: {
    async getNoti(context, id) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data);
          context.commit("setNoti", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error.response;
        });
    },
    deleteNoti({ commit }, data) {
      //console.log(data);
      return axios
        .delete(apiURL + data, { headers: authHeader() })
        .then(() => {
          //console.log("Delete is successfully");
          commit("deleteNoti", data);
        })
        .catch((error) => {
          //console.log(error.response);
          return error;
        });
    },
  },
};

<template>
  <v-container fluid>
    <v-breadcrumbs :items="itemsRouter">
      <template v-slot:divider>
        <v-icon size="25">mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
          <span :style="`color: ${item.color}`">
            {{ item.text.toUpperCase() }}
          </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-row justify="start" class="mx-3">
      <v-col
        class="d-flex justify-center"
        v-for="(item, index) in buildings"
        :key="index"
        cols="12"
        xs="12"
        sm="6"
        md="4"
        lg="4"
        xl="3"
      >
        <v-card id="card" class="mt-1 mb-0" :style="widthRespon()">
          <v-row class="pt-2">
            <v-col cols="5">
              <v-img
                :src="require('/src/assets/img.png')"
                class="ml-3 mt-2 mb-3"
                max-height="100"
                max-width="200"
                contain
              ></v-img>
            </v-col>
            <v-col cols="5">
              <v-list-item-content>
                <v-list-item-title class="text-h6 mt-2 font-weight-bold">
                  {{ item.buildingName }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="item.status === 'online'"
                  class="mb-2 mt-3 green--text"
                >
                  ● Online
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else class="mb-2 mt-3 red--text">
                  ● Offline
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-col>
            <v-col cols="2" class="ml-n2">
              <div class="d-flex justify-end mx-0">
                <router-link
                  :to="{ name: 'Events', params: { id: item.id } }"
                  style="color: inherit; text-decoration: none"
                >
                  <v-btn color="#D32F2F" icon>
                    <v-icon>mdi-car-brake-alert</v-icon>
                  </v-btn>
                </router-link>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <infinite-loading
      @infinite="infiniteHandler"
      v-if="this.load"
    ></infinite-loading>
  </v-container>
</template>
<script>
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    InfiniteLoading,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    this.$options.sockets.status = (data) => {
      //console.log(data);
      if (data.update == true) {
        this.realtime();
      }
    };
  },
  data() {
    return {
      msg: null,
      //isConnected: null,
      itemsRouter: [
        {
          text: "Dashboard",
          disabled: true,
          href: "/dashboard",
        },
      ],
      load: true,
      buildings: [],
      data: {
        last: 0,
        length: 0,
      },
    };
  },
  methods: {
    realtime() {
      this.data.last = 0;
      this.data.length = this.buildings.length;
      this.$store.dispatch("getBuildings", this.data).then((res) => {
        //console.log(res);
        this.buildings = res;
      });
    },
    infiniteHandler($state) {
      if (this.data.length === 0) {
        this.data.length = this.cheackScreenInitial();
      } else {
        this.data.length = this.cheackScreenNext();
      }
      this.data.last = this.buildings.length;
      //console.log(this.data);
      this.$store.dispatch("getBuildings", this.data).then((res) => {
        if (res.status != undefined) {
          if (res.status == 500) {
            this.load = false;
          }
        }
        if (res.length === 0) {
          this.load = false;
        }
        setTimeout(() => {
          for (let i = 0; i < res.length; i++) {
            this.buildings.push(res[i]);
          }
          $state.loaded();
        }, 100);
      });
    },
    widthRespon() {
      if (window.matchMedia("screen and (max-width:500px)").matches) {
        return "width: 300px";
      } else if (window.matchMedia("screen and (max-width:960px)").matches) {
        return "width: 400px";
      } else if (window.matchMedia("screen and (max-width:1264px)").matches) {
        return "width: 400px";
      } else {
        return "width: 700px";
      }
    },
    cheackScreenInitial() {
      if (window.matchMedia("screen and (max-width:500px)").matches) {
        return 5;
      } else if (window.matchMedia("screen and (max-width:960px)").matches) {
        return 6;
      } else if (window.matchMedia("screen and (max-width:1264px)").matches) {
        return 12;
      } else {
        return 20;
      }
    },
    cheackScreenNext() {
      if (window.matchMedia("screen and (max-width:960px)").matches) {
        return 3;
      } else if (window.matchMedia("screen and (max-width:1264px)").matches) {
        return 3;
      } else {
        return 4;
      }
    },
  },
};
</script>
<style lang="scss">
#card {
  border-top: 5px solid;
  border-top-color: #d32f2f;
}
.v-application a {
  color: #d32f2f;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}]},[_c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{attrs:{"items":_vm.itemsRouter},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',{attrs:{"size":"25"}},[_vm._v("mdi-chevron-right")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"disabled":item.disabled,"href":item.href}},[_c('span',{style:(("color: " + (item.color)))},[_vm._v(" "+_vm._s(item.text.toUpperCase())+" ")])])]}}])}),_c('v-card',{staticClass:"mx-6"},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"5","md":"3","sm":"3"}},[_vm._v(" Report ")]),_c('v-col',{attrs:{"align":"end"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"3","md":"4","sm":"4"}},[(_vm.getAccess.role === 'Superadmin')?_c('v-select',{attrs:{"items":_vm.getName,"item-value":"id","item-text":"buildingName","label":"Select Plant","color":"#D32F2F","item-color":"#D32F2F"},model:{value:(_vm.datasearch.id),callback:function ($$v) {_vm.$set(_vm.datasearch, "id", $$v)},expression:"datasearch.id"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","lg":"5","md":"5","sm":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","min-width":"auto","color":"#D32F2F"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Date range","prepend-icon":"mdi-calendar","readonly":"","color":"#D32F2F"},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","color":"#D32F2F","scrollable":"","no-title":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#D32F2F"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"#D32F2F"},on:{"click":function($event){return _vm.save(_vm.dates)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"align":"end","cols":"12","lg":"2","md":"2","sm":"2"}},[(!_vm.isMobile)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"#D32F2F","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.searchDate()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}}],null,false,609450749)},[_c('span',[_vm._v("Search")])]):_c('v-btn',{staticClass:"mr-2",attrs:{"color":"#D32F2F","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.searchDate()}}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getReports,"items-per-page":5,"loading":_vm.loading,"loading-text":"Loading... Please wait"}}),(_vm.isMobile)?_c('div',{staticClass:"\n          table-footer-prepend-mobile\n          d-flex\n          pl-2\n          align-center\n          justify-center\n        "},[_c('v-btn',{attrs:{"color":"error"}},[_c('download-excel',{attrs:{"data":_vm.getReports,"fields":_vm.columns,"type":"excel","name":"report_firealarm.xls"}},[_vm._v(" Download Excel ")])],1)],1):_c('div',{staticClass:"table-footer-prepend d-flex pl-2 align-center"},[_c('v-btn',{attrs:{"color":"error"}},[_c('download-excel',{attrs:{"data":_vm.getReports,"fields":_vm.columns,"type":"excel","name":"report_firealarm.xls"}},[_vm._v(" Download Excel ")])],1)],1)],1),_c('v-snackbar',{attrs:{"buttom":"","left":"","color":_vm.color,"timeout":"1500"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }